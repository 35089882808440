import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        try {
      
            var requestOptions = {
              credentials: 'include',
              method: 'GET',
              redirect: 'follow'
            };
      
            fetch('https://service.votibari.it/api/logout/', requestOptions)
              .then(response => response.json())
              .then(result => {
                navigate('/login');
              })
              .catch(error => {
                return;
              });
          } catch (error) {
            /*AntDialog.show({
              title: "OPS!",
              content: (
                <>
                  <div>{t("error_occurred")}</div>
                </>
              ),
              closeOnAction: true,
              actions: [[
                    {
                        key: 'cancel',
                        text: 'Chiudi',
                        bold: true,
                    }
                ]],
            });*/
            //setLoading(false);
          }
    }, []);
  
    return (
      <></>
    );
  };
  
  export default LogoutPage;