import React, { createContext, useContext, useState, useEffect } from 'react';

const AccountContext = createContext();

export const useAccount = () => {
  return useContext(AccountContext);
};

export const AccountProvider = ({ children,  tawkMessenger }) => {
  const [account, setAccount] = useState(null);

  const ChangeLogged = () => {
    aggiornaAccount();
    return null;
  };

  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        var requestOptions = {
            credentials: 'include',
            method: 'GET',
            redirect: 'follow'
          };
        const response = await fetch(`https://service.votibari.it/api/account/`, requestOptions);
        const accountData = await response.json();
        if(accountData.data.loggato) {
          try {
            tawkMessenger.current.visitor = {
              name  : accountData.data.nome+' '+accountData.data.cognome,
              email : accountData.data.email,
              hash  : accountData.data.hashassistenza
          };
          } catch (error) {
            console.error(error);
          }
        } else {
          try {
            tawkMessenger.current.visitor = {};
          } catch (error) {
            console.error(error);
          }
        }
        setAccount(accountData.data);
      } catch (error) {
        console.error('Errore nel recupero delle informazioni dell\'account', error);
        try {
          tawkMessenger.current.visitor = {};
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchAccountInfo();
  }, []);

 const aggiornaAccount = () => {
    const fetchAccountInfo = async () => {
      try {
        var requestOptions = {
            credentials: 'include',
            method: 'GET',
            redirect: 'follow'
          };
        const response = await fetch(`https://service.votibari.it/api/account/`, requestOptions);
        const accountData = await response.json();
        setAccount(accountData.data);
        if(accountData.data.loggato) {
          try {
            tawkMessenger.current.visitor = {
              name  : accountData.data.nome+' '+accountData.data.cognome,
              email : accountData.data.email,
              hash  : accountData.data.hashassistenza
          };
          } catch (error) {
            console.error(error);
          }
        } else {
          try {
            tawkMessenger.current.visitor = {};
          } catch (error) {
            console.error(error);
          }
        }
      } catch (error) {
        console.error('Errore nel recupero delle informazioni dell\'account', error);
      }
    };
    setTimeout(() => {
      fetchAccountInfo();
    }, 1000);
  };

  const value = {
    account
  };

  return (
    <AccountContext.Provider value={{account, ChangeLogged}}>
      {children}
    </AccountContext.Provider>
  );
};