import React from 'react';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAccount } from '../contexts/AccountContext';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

function HomePage() {
    const navigate = useNavigate();
    const { account } = useAccount();
    return (
        <Container style={{ marginTop: '16px'}}>
            <Card variant="outlined" style={{ marginBottom: '12px'}}>
                <CardContent>
                    <Typography variant="h5" component="div">
                        Ciao {account.nome} {account.cognome}
                    </Typography>
                    {account.ruolo=="edit" ? (
                        <Typography variant="body2">
                            Puoi eseguire azioni per tutte le sezioni.
                        </Typography>
                    ) : (
                        <>
                            <Typography variant="body2">
                                Puoi eseguire azioni per le seguenti sezioni:
                            </Typography>
                            <List>
                                {account.sezioni.map((sezione) => (
                                    <ListItem key={sezione.numerosezione} disablePadding>
                                        <ListItemText primary={`Sezione n°${sezione.numerosezione}`} secondary={sezione.nome} />
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    )}
                </CardContent>
            </Card>
            <Card style={{ marginBottom: '8px'}}>
                <CardActionArea
                    onClick={() => navigate('/inviaballottaggio/')}
                >
                    <CardContent>
                    <Typography variant="h5" component="div" sx={{fontWeight:'bold'}}>
                        Invia dati ballottaggio sindaco
                    </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            {account.sezioni.some(sezione => sezione.municipio === "3") && (
                <Card style={{ marginBottom: '8px'}}>
                    <CardActionArea
                        onClick={() => navigate('/inviaballottaggio3/')}
                    >
                        <CardContent>
                        <Typography variant="h5" component="div" sx={{fontWeight:'bold'}}>
                            Invia dati ballottaggio presidente municipio 3
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            )}
            {account.sezioni.some(sezione => sezione.municipio === "5") && (
                <Card style={{ marginBottom: '8px'}}>
                    <CardActionArea
                        onClick={() => navigate('/inviaballottaggio5/')}
                    >
                        <CardContent>
                        <Typography variant="h5" component="div" sx={{fontWeight:'bold'}}>
                            Invia dati ballottaggio presidente municipio 5
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            )}
            {account.ruolo=="edit" && (
                <Card style={{ marginBottom: '8px'}}>
                    <CardActionArea
                        onClick={() => navigate('/bloccasezioni/')}
                    >
                        <CardContent>
                        <Typography variant="h5" component="div" sx={{fontWeight:'bold'}}>
                            Blocca sezioni
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            )}
            {account.ruolo=="edit" && (
                <Card style={{ marginBottom: '8px'}}>
                    <CardActionArea
                        onClick={() => navigate('/bloccasezionimunicipi/')}
                    >
                        <CardContent>
                        <Typography variant="h5" component="div" sx={{fontWeight:'bold'}}>
                            Blocca sezioni municipi
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            )}
            {/*<Card style={{ marginBottom: '8px'}}>
                <CardActionArea
                    onClick={() => navigate('/inviasindaco/')}
                >
                    <CardContent>
                    <Typography variant="h5" component="div" sx={{fontWeight:'bold'}}>
                        Invia dati sindaco
                    </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Card style={{ marginBottom: '8px'}}>
                <CardActionArea
                    onClick={() => navigate('/invialiste/')}
                >
                    <CardContent>
                    <Typography variant="h5" component="div" sx={{fontWeight:'bold'}}>
                        Invia dati liste
                    </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>*/}
            <Card style={{ marginBottom: '8px'}}>
                <CardActionArea onClick={() => window.open("/manuale/", "_blank")}>
                    <CardContent>
                    <Typography variant="h6" component="div" sx={{fontWeight:'bold'}}>
                        Consulta vademecum
                    </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Card>
                <CardActionArea onClick={() => navigate('/logout/')}>
                    <CardContent>
                    <Typography variant="h6" component="div" sx={{fontWeight:'bold'}}>
                        Logout
                    </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Container>
    );
}

export default HomePage;