import React from 'react';
import { Container, FormLabel, FormControlLabel, RadioGroup, FormControl, Radio, TextField, Divider, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { useAccount } from '../contexts/AccountContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';

function InviaBallottaggioM3() {
    const { account } = useAccount();
    const navigate = useNavigate();
    const [openDialogErrore, setOpenDialogErrore] = React.useState(false);
    const [DialogErroreMsg, setDialogErroreMsg] = React.useState('');
    const [sezione, setSezione] = React.useState(0);
    const [tipologia, setTipologia] = React.useState('');
    const [verdoscia, setVerdoscia] = React.useState();
    const [colonna, setColonna] = React.useState();
    const [vtotali, setVTotali] = React.useState();
    const [bianche, setBianche] = React.useState();
    const [nulle, setNulle] = React.useState();
    const [invio, setInvio] = React.useState(false);

    const handleCloseDialog = () => {
        setOpenDialogErrore(false);
      };

    const inviaDati = (e) => {
        e.preventDefault();
        var nnsezione=parseInt(sezione);
        if(account.sezioni.filter(sezione => sezione.municipio === "3").some(sezione => sezione.numerosezione == nnsezione)==false) {
            setDialogErroreMsg('Selezionare una sezione');
            setOpenDialogErrore(true);
            return;
        }
        if(/^\d+$/.test(vtotali) === false || /^\d+$/.test(bianche) === false || /^\d+$/.test(nulle) === false || /^\d+$/.test(verdoscia) === false || /^\d+$/.test(colonna) === false) {
            setDialogErroreMsg('I dati inseriti non sono validi');
            setOpenDialogErrore(true);
            return;
        }
        setInvio(true);
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("sezione", sezione);
            urlencoded.append("verdoscia", verdoscia);
            urlencoded.append("colonna", colonna);
            urlencoded.append("votanti_totali", vtotali);
            urlencoded.append("schede_bianche", bianche);
            urlencoded.append("schede_nulle", nulle);
            urlencoded.append("tipo", tipologia);

            var requestOptions = {
                credentials: 'include',
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };
    
            fetch("https://service.votibari.it/api/invia-ballottaggio-m3/", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if(result.inviato) {
                        setDialogErroreMsg('Dati inviati correttamente');
                        setOpenDialogErrore(true);
                        setInvio(false);
                        navigate("/");
                    } else {
                        setDialogErroreMsg(result.message);
                        setOpenDialogErrore(true);
                        setInvio(false);
                    }
                })
                .catch(error => {
                    setDialogErroreMsg('Errore durante l\'invio dei dati');
                    setOpenDialogErrore(true);
                    setInvio(false);
                });
        } catch (error) {
            // Handle error and show error message
            console.error('Error during registration:', error);
            setOpenDialogErrore(true);
            setDialogErroreMsg("Errore durante l'invio dei dati");
            setInvio(false);
          }
    }
      
            

    const handleChangeSezione = (event) => {
        setSezione(event.target.value);
    };

    const handleChangeTipologia = (event) => {
        setTipologia(event.target.value);
    };

    return (
        <Container style={{ marginTop: '16px', marginBottom:'36px'}}>
            <h1>Invia dati ballottaggio municipio 3</h1>
            {account.ruolo!="edit" && (<Alert severity="warning"><p style={{marginTop:0}}>Compila tutti i campi con i risultati, anche parziali, ma sempre in maniera complessiva. Esempio:</p>
                <ul>
                    <li><strong>Schede scrutinate:</strong> 100</li>
                    <li><strong>Schede bianche:</strong> 1</li>
                    <li><strong>Schede nulle:</strong> 1</li>
                    <li><strong>Verdoscia:</strong> 49 voti</li>
                    <li><strong>Colonna:</strong> 49 voti</li>
                </ul>
                <p>Il totale dei voti validi (49+49=98) sommato alle schede bianche e nulle (1+1=2) corrisponde al numero delle schede scrutinate fino al quel momento (100).</p>
                <p>Se dovessero capitare casi particolari come alcune schede "messe da parte" basta non inserirle nel numero delle schede scrutinate (perché in effetti non ancora computabili in nessun modo).</p>
                <p>Sarà possibile inserire nuovamente i dati parziali ricompilando il modulo sempre in maniera complessiva ogni volta che si vuole fino alla conclusione delle operazioni di scrutinio, quando potrete decidere di inserire i risultati definitivi.</p>
            </Alert>)}
            <form onSubmit={inviaDati}>
            {account.ruolo=="edit" ? (
                <>
                <Autocomplete
                    disablePortal
                    id="sezioni"
                    options={account.sezioni.filter(sezione => sezione.municipio === "3").map((sezione) => sezione.numerosezione)}
                    sx={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} label="Sezione" />}
                    onChange={(event, value) => setSezione(value)}
                    />
                </>
            ) : (
                <FormControl fullWidth>
                    <FormLabel id="sezioni">Sezioni</FormLabel>
                    <RadioGroup
                        aria-labelledby="sezioni"
                        value={sezione}
                        onChange={handleChangeSezione}
                    >
                        {account.sezioni.filter(sezione => sezione.municipio === "3").map((sezione) => {
                            return <FormControlLabel value={sezione.numerosezione} control={<Radio />} label={`Sezione n° ${sezione.numerosezione}`} />
                        })}
                    </RadioGroup>
                </FormControl>
            )}
            {(sezione !== 0) && (
                <FormControl fullWidth sx={{marginTop:'18px'}}>
                    <FormLabel id="dati">I dati sono</FormLabel>
                    <RadioGroup
                        aria-labelledby="dati"
                        value={tipologia}
                        onChange={handleChangeTipologia}
                    >
                        <FormControlLabel value="parziali" control={<Radio />} label={`Dati parziali`} />
                        <FormControlLabel value="definitivi" control={<Radio />} label={`Dati definitivi`} />
                    </RadioGroup>
                </FormControl>
            )}
            {(sezione !== 0 && tipologia!=="") && (
                <>
                    <Divider textAlign="left" sx={{marginTop:'18px'}}><Chip label="Dati schede" size="small" /></Divider>
                    <TextField
                        fullWidth
                        required
                        id="totali"
                        label="Schede scrutinate"
                        value={vtotali}
                        onChange={(e) => setVTotali(e.target.value)}
                        margin="normal"
                        min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="bianche"
                        label="Schede bianche"
                        value={bianche}
                        onChange={(e) => setBianche(e.target.value)}
                        margin="normal"
                        min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="nulle"
                        label="Schede nulle"
                        value={nulle}
                        onChange={(e) => setNulle(e.target.value)}
                        margin="normal"
                        min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <Divider textAlign="left" sx={{marginTop:'8px'}}><Chip label="Sindaci" size="small" /></Divider>
                    <TextField
                        fullWidth
                        required
                        id="verdoscia"
                        label="Verdoscia"
                        value={verdoscia}
                        onChange={(e) => setVerdoscia(e.target.value)}
                        margin="normal"
                        min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="colonna"
                        label="Colonna"
                        value={colonna}
                        onChange={(e) => setColonna(e.target.value)}
                        margin="normal"
                        min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <LoadingButton type="submit" variant="contained" color="primary" loading={invio} loadingIndicator="Invio in corso" sx={{ width: '100%', mt: 1 }}>Invia</LoadingButton>
                </>
            )}
            </form>
            <Dialog
                open={openDialogErrore}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{DialogErroreMsg}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} autoFocus>CHIUDI</Button>
                </DialogActions>
                </Dialog>
        </Container>
    );
}

export default InviaBallottaggioM3;