import React, { useState } from 'react';
import { Container, FormLabel, FormControlLabel, RadioGroup, FormControl, Radio, TextField, Divider, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { useAccount } from '../contexts/AccountContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';

function InviaLista() {
    const { account } = useAccount();
    const navigate = useNavigate();
    const [openDialogErrore, setOpenDialogErrore] = React.useState(false);
    const [DialogErroreMsg, setDialogErroreMsg] = React.useState('');
    const [sezione, setSezione] = React.useState(0);
    const [tipologia, setTipologia] = React.useState('');
    const [sc_sciacovelliSindaco, setScSciacovelliSindaco] = useState(0);
    const [sc_noiPerBari, setScNoiPerBari] = useState(0);
    const [la_bariCittaDEuropa, setLaBariCittaDEuropa] = useState(0);
    const [la_m5s, setLaM5s] = useState(0);
    const [la_genUrbana, setLaGenUrbana] = useState(0);
    const [la_bbc, setLaBbc] = useState(0);
    const [la_pci, setLaPci] = useState(0);
    const [la_laforgiaSindaco, setLaLaforgiaSindaco] = useState(0);
    const [le_noiPopolario, setLeNoiPopolario] = useState(0);
    const [le_progettoBari, setLeProgettoBari] = useState(0);
    const [le_pd, setLePd] = useState(0);
    const [le_lecceseSindaco, setLeLecceseSindaco] = useState(0);
    const [le_con, setLeCon] = useState(0);
    const [le_decaroPerBari, setLeDecaroPerBari] = useState(0);
    const [le_verdi, setLeVerdi] = useState(0);
    const [ro_barix, setRoBarix] = useState(0);
    const [ro_agora, setRoAgora] = useState(0);
    const [ro_conca, setRoConca] = useState(0);
    const [ro_noiModerati, setRoNoiModerati] = useState(0);
    const [ro_npsi, setRoNpsi] = useState(0);
    const [ro_udc, setRoUdc] = useState(0);
    const [ro_fdi, setRoFdi] = useState(0);
    const [ro_forzaItalia, setRoForzaItalia] = useState(0);
    const [ro_romitoSindaco, setRoRomitoSindaco] = useState(0);
    const [ro_pensionatiInvalidi, setRoPensionatiInvalidi] = useState(0);
    const [ma_oltre, setMaOltre] = useState(0);
    const [vtotali, setVTotali] = React.useState(0);
    const [bianche, setBianche] = React.useState(0);
    const [nulle, setNulle] = React.useState(0);
    const [invio, setInvio] = React.useState(false);

    const handleCloseDialog = () => {
        setOpenDialogErrore(false);
      };

    const inviaDati = (e) => {
        e.preventDefault();
        setInvio(true);
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("sezione", sezione);
            urlencoded.append("sc_sciacovelliSindaco", sc_sciacovelliSindaco);
            urlencoded.append("sc_noiPerBari", sc_noiPerBari);
            urlencoded.append("la_bariCittaDEuropa", la_bariCittaDEuropa);
            urlencoded.append("la_m5s", la_m5s);
            urlencoded.append("la_genUrbana", la_genUrbana);
            urlencoded.append("la_bbc", la_bbc);
            urlencoded.append("la_pci", la_pci);
            urlencoded.append("la_laforgiaSindaco", la_laforgiaSindaco);
            urlencoded.append("le_noiPopolario", le_noiPopolario);
            urlencoded.append("le_progettoBari", le_progettoBari);
            urlencoded.append("le_pd", le_pd);
            urlencoded.append("le_lecceseSindaco", le_lecceseSindaco);
            urlencoded.append("le_con", le_con);
            urlencoded.append("le_decaroPerBari", le_decaroPerBari);
            urlencoded.append("le_verdi", le_verdi);
            urlencoded.append("ro_barix", ro_barix);
            urlencoded.append("ro_agora", ro_agora);
            urlencoded.append("ro_conca", ro_conca);
            urlencoded.append("ro_noiModerati", ro_noiModerati);
            urlencoded.append("ro_npsi", ro_npsi);
            urlencoded.append("ro_udc", ro_udc);
            urlencoded.append("ro_fdi", ro_fdi);
            urlencoded.append("ro_forzaItalia", ro_forzaItalia);
            urlencoded.append("ro_romitoSindaco", ro_romitoSindaco);
            urlencoded.append("ro_pensionatiInvalidi", ro_pensionatiInvalidi);
            urlencoded.append("ma_oltre", ma_oltre);
            urlencoded.append("votanti_totali", vtotali);
            urlencoded.append("schede_bianche", bianche);
            urlencoded.append("schede_nulle", nulle);
            urlencoded.append("tipo", tipologia);

            var requestOptions = {
                credentials: 'include',
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };
    
            fetch("https://service.votibari.it/api/invia-lista/", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if(result.inviato) {
                        setDialogErroreMsg('Dati inviati correttamente');
                        setOpenDialogErrore(true);
                        setInvio(false);
                        navigate("/");
                    } else {
                        setDialogErroreMsg('Errore durante l\'invio dei dati');
                        setOpenDialogErrore(true);
                        setInvio(false);
                    }
                })
                .catch(error => {
                    setDialogErroreMsg('Errore durante l\'invio dei dati');
                    setOpenDialogErrore(true);
                    setInvio(false);
                });
        } catch (error) {
            // Handle error and show error message
            console.error('Error during registration:', error);
            setOpenDialogErrore(true);
            setDialogErroreMsg("Errore durante l'invio dei dati");
            setInvio(false);
          }
    }
      
            

    const handleChangeSezione = (event) => {
        setSezione(event.target.value);
    };

    const handleChangeTipologia = (event) => {
        setTipologia(event.target.value);
    };

    return (
        <Container style={{ marginTop: '16px', marginBottom:'36px'}}>
            <h1>Invia dati liste</h1>
            <form onSubmit={inviaDati}>
            <FormControl fullWidth>
                <FormLabel id="sezioni">Sezioni</FormLabel>
                <RadioGroup
                    aria-labelledby="sezioni"
                    value={sezione}
                    onChange={handleChangeSezione}
                >
                     {account.sezioni.map((sezione) => {
                        return <FormControlLabel value={sezione.numerosezione} control={<Radio />} label={`Sezione n° ${sezione.numerosezione}`} />
                    })}
                </RadioGroup>
            </FormControl>
            {(sezione !== 0) && (
                <FormControl fullWidth sx={{marginTop:'18px'}}>
                    <FormLabel id="dati">I dati sono</FormLabel>
                    <RadioGroup
                        aria-labelledby="dati"
                        value={tipologia}
                        onChange={handleChangeTipologia}
                    >
                        <FormControlLabel value="parziali" control={<Radio />} label={`Dati parziali`} />
                        <FormControlLabel value="definitivi" control={<Radio />} label={`Dati definitivi`} />
                    </RadioGroup>
                </FormControl>
            )}
            {(sezione !== 0 && tipologia!=="") && (
                <>
                    <Divider textAlign="left" sx={{marginTop:'18px'}}><Chip label="Dati schede" size="small" /></Divider>
                    <TextField
                        fullWidth
                        required
                        id="totali"
                        label="Schede scrutinate"
                        value={vtotali}
                        onChange={(e) => setVTotali(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="bianche"
                        label="Schede bianche"
                        value={bianche}
                        onChange={(e) => setBianche(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="nulle"
                        label="Schede nulle"
                        value={nulle}
                        onChange={(e) => setNulle(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <Divider textAlign="left" sx={{marginTop:'8px'}}><Chip label="Liste" size="small" /></Divider>
                    <Divider sx={{marginTop:'4px'}}>Sciacovelli</Divider>
                    <TextField
                        fullWidth
                        required
                        id="sc_sciacovelliSindaco"
                        label="Sciacovelli Sindaco"
                        value={sc_sciacovelliSindaco}
                        onChange={(e) => setScSciacovelliSindaco(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="sc_noiPerBari"
                        label="Noi Per Bari"
                        value={sc_noiPerBari}
                        onChange={(e) => setScNoiPerBari(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <Divider sx={{marginTop:'4px'}}>Laforgia</Divider>
                    <TextField
                        fullWidth
                        required
                        id="la_bariCittaDEuropa"
                        label="Bari Città d'Europa"
                        value={la_bariCittaDEuropa}
                        onChange={(e) => setLaBariCittaDEuropa(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="la_m5s"
                        label="M5S"
                        value={la_m5s}
                        onChange={(e) => setLaM5s(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="la_genUrbana"
                        label="Generazione Urbana"
                        value={la_genUrbana}
                        onChange={(e) => setLaGenUrbana(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="la_bbc"
                        label="Bari Bene Comune"
                        value={la_bbc}
                        onChange={(e) => setLaBbc(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="la_pci"
                        label="PCI"
                        value={la_pci}
                        onChange={(e) => setLaPci(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="la_laforgiaSindaco"
                        label="Laforgia Sindaco"
                        value={la_laforgiaSindaco}
                        onChange={(e) => setLaLaforgiaSindaco(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <Divider sx={{marginTop:'4px'}}>Leccese</Divider>
                    <TextField
                        fullWidth
                        required
                        id="le_noiPopolario"
                        label="Noi Popolari"
                        value={le_noiPopolario}
                        onChange={(e) => setLeNoiPopolario(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="le_progettoBari"
                        label="Progetto Bari"
                        value={le_progettoBari}
                        onChange={(e) => setLeProgettoBari(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="le_pd"
                        label="Partito Democratico"
                        value={le_pd}
                        onChange={(e) => setLePd(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="le_lecceseSindaco"
                        label="Leccese Sindaco"
                        value={le_lecceseSindaco}
                        onChange={(e) => setLeLecceseSindaco(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="le_con"
                        label="CON"
                        value={le_con}
                        onChange={(e) => setLeCon(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="le_decaroPerBari"
                        label="Decaro Per Bari"
                        value={le_decaroPerBari}
                        onChange={(e) => setLeDecaroPerBari(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="le_verdi"
                        label="Europa Verde"
                        value={le_verdi}
                        onChange={(e) => setLeVerdi(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <Divider sx={{marginTop:'4px'}}>Romito</Divider>
                    <TextField
                        fullWidth
                        required
                        id="ro_barix"
                        label="Bari X Fabio Romito"
                        value={ro_barix}
                        onChange={(e) => setRoBarix(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="ro_agora"
                        label="Agora"
                        value={ro_agora}
                        onChange={(e) => setRoAgora(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="ro_conca"
                        label="Mario Conca per Bari"
                        value={ro_conca}
                        onChange={(e) => setRoConca(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="ro_noiModerati"
                        label="Noi Moderati"
                        value={ro_noiModerati}
                        onChange={(e) => setRoNoiModerati(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="ro_npsi"
                        label="NPSI"
                        value={ro_npsi}
                        onChange={(e) => setRoNpsi(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="ro_udc"
                        label="UDC"
                        value={ro_udc}
                        onChange={(e) => setRoUdc(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="ro_fdi"
                        label="Fratelli d'Italia"
                        value={ro_fdi}
                        onChange={(e) => setRoFdi(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="ro_forzaItalia"
                        label="Forza Italia"
                        value={ro_forzaItalia}
                        onChange={(e) => setRoForzaItalia(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="ro_romitoSindaco"
                        label="Romito Sindaco"
                        value={ro_romitoSindaco}
                        onChange={(e) => setRoRomitoSindaco(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="ro_pensionatiInvalidi"
                        label="Pensionati Invalidi"
                        value={ro_pensionatiInvalidi}
                        onChange={(e) => setRoPensionatiInvalidi(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <Divider sx={{marginTop:'4px'}}>Mangano</Divider>
                    <TextField
                        fullWidth
                        required
                        id="ma_oltre"
                        label="Oltre"
                        value={ma_oltre}
                        onChange={(e) => setMaOltre(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <LoadingButton type="submit" variant="contained" color="primary" loading={invio} loadingIndicator="Invio in corso" sx={{ width: '100%', mt: 1 }}>Invia</LoadingButton>
                </>
            )}
            </form>
            <Dialog
                open={openDialogErrore}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{DialogErroreMsg}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} autoFocus>CHIUDI</Button>
                </DialogActions>
                </Dialog>
        </Container>
    );
}

export default InviaLista;