import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
      "fontFamily": `"Radio Canada Big", sans-serif`,
    },
    palette: {
      mode: 'dark'
    }
});
export default theme;