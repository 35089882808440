import React from 'react';
import { Container, FormLabel, FormControlLabel, RadioGroup, FormControl, Radio, TextField, Divider, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { useAccount } from '../contexts/AccountContext';
import LoadingButton from '@mui/lab/LoadingButton';

function InviaAffluenza() {
    const { account } = useAccount();
    const [openDialogErrore, setOpenDialogErrore] = React.useState(false);
    const [DialogErroreMsg, setDialogErroreMsg] = React.useState('');
    const [sezione, setSezione] = React.useState(0);
    const [affluenza8_23, setAffluenza8_23] = React.useState(0);
    const [affluenza9_12, setAffluenza9_12] = React.useState(0);
    const [affluenza9_19, setAffluenza9_19] = React.useState(0);
    const [affluenza9_23, setAffluenza9_23] = React.useState(0);
    const [invio, setInvio] = React.useState(false);

    const handleCloseDialog = () => {
        setOpenDialogErrore(false);
      };

    const inviaDati = (e) => {
        e.preventDefault();
        setInvio(true);
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("sezione", sezione);
            urlencoded.append("affluenza8_23", affluenza8_23);
            urlencoded.append("affluenza9_12", affluenza9_12);
            urlencoded.append("affluenza9_19", affluenza9_19);
            urlencoded.append("affluenza9_23", affluenza9_23);

            var requestOptions = {
                credentials: 'include',
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };
    
            fetch("https://service.votibari.it/api/invia-affluenza/", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if(result.inviato) {
                        setDialogErroreMsg('Dati inviati correttamente');
                        setOpenDialogErrore(true);
                        setInvio(false);
                    } else {
                        setDialogErroreMsg('Errore durante l\'invio dei dati');
                        setOpenDialogErrore(true);
                        setInvio(false);
                    }
                })
                .catch(error => {
                    setDialogErroreMsg('Errore durante l\'invio dei dati');
                    setOpenDialogErrore(true);
                    setInvio(false);
                });
        } catch (error) {
            // Handle error and show error message
            console.error('Error during registration:', error);
            setOpenDialogErrore(true);
            setDialogErroreMsg("Errore durante l'invio dei dati");
            setInvio(false);
          }
    }
     
    const handleChangeSezione = (event) => {
        setSezione(event.target.value);
    };

    return (
        <Container style={{ marginTop: '16px', marginBottom:'36px'}}>
            <h1>Invia dati affluenza</h1>
            <form onSubmit={inviaDati}>
            <FormControl fullWidth>
                <FormLabel id="sezioni">Sezioni</FormLabel>
                <RadioGroup
                    aria-labelledby="sezioni"
                    value={sezione}
                    onChange={handleChangeSezione}
                >
                     {account.sezioni.map((sezione) => {
                        return <FormControlLabel value={sezione.numerosezione} control={<Radio />} label={`Sezione n° ${sezione.numerosezione}`} />
                    })}
                </RadioGroup>
            </FormControl>
                {(sezione !== 0) && (<div>
                <Divider textAlign="left" sx={{marginTop:'18px'}}><Chip label="8 Giugno" size="small" /></Divider>
                <TextField
                    fullWidth
                    required
                    id="ore23"
                    label="ore 23"
                    value={affluenza8_23}
                    onChange={(e) => setAffluenza8_23(e.target.value)}
                    margin="normal"
                    type="number" min="0"
                    inputmode="numeric" pattern="[0-9]*"
                />
                <Divider textAlign="left" sx={{marginTop:'18px'}}><Chip label="9 Giugno" size="small" /></Divider>
                <TextField
                    fullWidth
                    required
                    id="ore12"
                    label="ore 12"
                    value={affluenza9_12}
                    onChange={(e) => setAffluenza9_12(e.target.value)}
                    margin="normal"
                    type="number" min="0"
                    inputmode="numeric" pattern="[0-9]*"
                />
                <TextField
                    fullWidth
                    required
                    id="ore19"
                    label="ore 19"
                    value={affluenza9_19}
                    onChange={(e) => setAffluenza9_19(e.target.value)}
                    margin="normal"
                    type="number" min="0"
                    inputmode="numeric" pattern="[0-9]*"
                />
                <TextField
                    fullWidth
                    required
                    id="ore23_9"
                    label="ore 23"
                    value={affluenza9_23}
                    onChange={(e) => setAffluenza9_23(e.target.value)}
                    margin="normal"
                    type="number" min="0"
                    inputmode="numeric" pattern="[0-9]*"
                />
                <LoadingButton type="submit" variant="contained" color="primary" loading={invio} loadingIndicator="Invio in corso" sx={{ width: '100%', mt: 1 }}>Invia</LoadingButton>
                </div>)}
            </form>
            <Dialog
                open={openDialogErrore}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{DialogErroreMsg}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} autoFocus>CHIUDI</Button>
                </DialogActions>
                </Dialog>
        </Container>
    );
}

export default InviaAffluenza;