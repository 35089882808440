import React, { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import { AccountProvider } from './contexts/AccountContext';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import theme from './utils/theme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import RequireAuth from './components/auth/requireAuth';
import Bottom from './components/website/bottom';
import Top from './components/website/top';
import HomePage from './pages/HomePage';
import InviaSindaco from './pages/InviaSindaco';
import InviaLista from './pages/InviaLista';
import InviaAffluenza from './pages/InviaAffluenza';
import InviaBallottaggio from './pages/InviaBallottaggio';
import BloccaSezioni from './pages/BloccaSezioni';
import BloccaSezioniMunicipi from './pages/BloccaSezioniMunicipi';
import InviaBallottaggioM3 from './pages/InviaBallottaggioM3';
import InviaBallottaggioM5 from './pages/InviaBallottaggioM5';

function App() {
  const tawkMessenger = useRef();
  return (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <AccountProvider tawkMessenger={tawkMessenger}>
          <Router>
              <Routes>
                <Route path="/" exact element={<><RequireAuth><Top tawkMessenger={tawkMessenger} /><HomePage /></RequireAuth></>} />
                <Route path="/inviaaffluenza/" exact element={<><RequireAuth><Top tawkMessenger={tawkMessenger} /><InviaAffluenza /></RequireAuth></>} />
                <Route path="/invialiste/" exact element={<><RequireAuth><Top tawkMessenger={tawkMessenger} /><InviaLista /></RequireAuth></>} />
                <Route path="/inviasindaco/" exact element={<><RequireAuth><Top tawkMessenger={tawkMessenger} /><InviaSindaco /></RequireAuth></>} />
                <Route path="/inviaballottaggio/" exact element={<><RequireAuth><Top tawkMessenger={tawkMessenger} /><InviaBallottaggio /></RequireAuth></>} />
                <Route path="/inviaballottaggio3/" exact element={<><RequireAuth><Top tawkMessenger={tawkMessenger} /><InviaBallottaggioM3 /></RequireAuth></>} />
                <Route path="/inviaballottaggio5/" exact element={<><RequireAuth><Top tawkMessenger={tawkMessenger} /><InviaBallottaggioM5 /></RequireAuth></>} />
                <Route path="/bloccasezioni/" exact element={<><RequireAuth><Top tawkMessenger={tawkMessenger} /><BloccaSezioni /></RequireAuth></>} />
                <Route path="/bloccasezionimunicipi/" exact element={<><RequireAuth><Top tawkMessenger={tawkMessenger} /><BloccaSezioniMunicipi /></RequireAuth></>} />
                <Route path="/logout/" exact element={<><RequireAuth><LogoutPage /></RequireAuth></>} />
                <Route path="/login" exact element={<><LoginPage /></>} />
                {/*<Route path="*" exact element={<><Pagina404 /><ScrollToTop /></>} />*/}
              </Routes>
          </Router>
        </AccountProvider>
        <TawkMessengerReact
          propertyId="665b123e9a809f19fb37ace0"
          widgetId="1hv9ruhcd"
          ref={tawkMessenger} />
    </ThemeProvider>
  );
}

export default App;
