import React, { useEffect } from 'react';
import { Container, FormLabel, FormControlLabel, RadioGroup, FormControl, Radio, TextField, Divider, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { useAccount } from '../contexts/AccountContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';

function BloccaSezioni() {
    const { account } = useAccount();
    const navigate = useNavigate();
    const [openDialogErrore, setOpenDialogErrore] = React.useState(false);
    const [DialogErroreMsg, setDialogErroreMsg] = React.useState('');
    const [sezioni, setSezioni] = React.useState("");
    const [invio, setInvio] = React.useState(false);

    const handleCloseDialog = () => {
        setOpenDialogErrore(false);
    };

    useEffect(() => {
        console.log(account.id);
        if(account.ruolo !="edit") {
            navigate('/');
        }
        var requestOptions = {
            credentials: 'include',
            method: 'GET',
            redirect: 'follow'
        };
        fetch("https://service.votibari.it/api/sezionibloccate/", requestOptions)
            .then(response => response.json())
            .then(result => {
                setSezioni(result.sezioni);
            })
            .catch(error => {
                setDialogErroreMsg('Errore durante il recupero dei dati');
                setOpenDialogErrore(true);
            });
    }, []);

    const inviaDati = (e) => {
        e.preventDefault();
        setInvio(true);
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("sezioni", sezioni);

            var requestOptions = {
                credentials: 'include',
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };
    
            fetch("https://service.votibari.it/api/bloccasezioni/", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if(result.inviato) {
                        setDialogErroreMsg('Dati inviati correttamente');
                        setOpenDialogErrore(true);
                        setInvio(false);
                        navigate("/");
                    } else {
                        setDialogErroreMsg(result.message);
                        setOpenDialogErrore(true);
                        setInvio(false);
                    }
                })
                .catch(error => {
                    setDialogErroreMsg('Errore durante l\'invio dei dati');
                    setOpenDialogErrore(true);
                    setInvio(false);
                });
        } catch (error) {
            // Handle error and show error message
            console.error('Error during registration:', error);
            setOpenDialogErrore(true);
            setDialogErroreMsg("Errore durante l'invio dei dati");
            setInvio(false);
          }
    }

    return (
        <Container style={{ marginTop: '16px', marginBottom:'36px'}}>
            <h1>Blocca le sezioni</h1>
            <form onSubmit={inviaDati}>
                <TextField
                    label="Sezioni da bloccare"
                    placeholder="Sezioni da bloccare"
                    multiline
                    fullWidth
                    value={sezioni}
                    onChange={(e) => setSezioni(e.target.value)}
                />
                <LoadingButton type="submit" variant="contained" color="primary" loading={invio} loadingIndicator="Invio in corso" sx={{ width: '100%', mt: 1 }}>Invia</LoadingButton>
            </form>
            <Dialog
                open={openDialogErrore}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{DialogErroreMsg}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} autoFocus>CHIUDI</Button>
                </DialogActions>
                </Dialog>
        </Container>
    );
}

export default BloccaSezioni;