import React from 'react';
import { Container, FormLabel, FormControlLabel, RadioGroup, FormControl, Radio, TextField, Divider, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { useAccount } from '../contexts/AccountContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';

function InviaSindaco() {
    const { account } = useAccount();
    const navigate = useNavigate();
    const [openDialogErrore, setOpenDialogErrore] = React.useState(false);
    const [DialogErroreMsg, setDialogErroreMsg] = React.useState('');
    const [sezione, setSezione] = React.useState(0);
    const [tipologia, setTipologia] = React.useState('');
    const [leccese, setLeccese] = React.useState(0);
    const [laforgia, setLaforgia] = React.useState(0);
    const [romito, setRomito] = React.useState(0);
    const [sciacovelli, setSciacovelli] = React.useState(0);
    const [mangano, setMangano] = React.useState(0);
    const [vtotali, setVTotali] = React.useState(0);
    const [bianche, setBianche] = React.useState(0);
    const [nulle, setNulle] = React.useState(0);
    const [invio, setInvio] = React.useState(false);

    const handleCloseDialog = () => {
        setOpenDialogErrore(false);
      };

    const inviaDati = (e) => {
        e.preventDefault();
        setInvio(true);
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("sezione", sezione);
            urlencoded.append("leccese", leccese);
            urlencoded.append("laforgia", laforgia);
            urlencoded.append("romito", romito);
            urlencoded.append("sciacovelli", sciacovelli);
            urlencoded.append("mangano", mangano);
            urlencoded.append("votanti_totali", vtotali);
            urlencoded.append("schede_bianche", bianche);
            urlencoded.append("schede_nulle", nulle);
            urlencoded.append("tipo", tipologia);

            var requestOptions = {
                credentials: 'include',
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };
    
            fetch("https://service.votibari.it/api/invia-sindaco/", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if(result.inviato) {
                        setDialogErroreMsg('Dati inviati correttamente');
                        setOpenDialogErrore(true);
                        setInvio(false);
                        navigate("/");
                    } else {
                        setDialogErroreMsg('Errore durante l\'invio dei dati');
                        setOpenDialogErrore(true);
                        setInvio(false);
                    }
                })
                .catch(error => {
                    setDialogErroreMsg('Errore durante l\'invio dei dati');
                    setOpenDialogErrore(true);
                    setInvio(false);
                });
        } catch (error) {
            // Handle error and show error message
            console.error('Error during registration:', error);
            setOpenDialogErrore(true);
            setDialogErroreMsg("Errore durante l'invio dei dati");
            setInvio(false);
          }
    }
      
            

    const handleChangeSezione = (event) => {
        setSezione(event.target.value);
    };

    const handleChangeTipologia = (event) => {
        setTipologia(event.target.value);
    };

    return (
        <Container style={{ marginTop: '16px', marginBottom:'36px'}}>
            <h1>Invia dati sindaco</h1>
            <form onSubmit={inviaDati}>
            <FormControl fullWidth>
                <FormLabel id="sezioni">Sezioni</FormLabel>
                <RadioGroup
                    aria-labelledby="sezioni"
                    value={sezione}
                    onChange={handleChangeSezione}
                >
                     {account.sezioni.map((sezione) => {
                        return <FormControlLabel value={sezione.numerosezione} control={<Radio />} label={`Sezione n° ${sezione.numerosezione}`} />
                    })}
                </RadioGroup>
            </FormControl>
            {(sezione !== 0) && (
                <FormControl fullWidth sx={{marginTop:'18px'}}>
                    <FormLabel id="dati">I dati sono</FormLabel>
                    <RadioGroup
                        aria-labelledby="dati"
                        value={tipologia}
                        onChange={handleChangeTipologia}
                    >
                        <FormControlLabel value="parziali" control={<Radio />} label={`Dati parziali`} />
                        <FormControlLabel value="definitivi" control={<Radio />} label={`Dati definitivi`} />
                    </RadioGroup>
                </FormControl>
            )}
            {(sezione !== 0 && tipologia!=="") && (
                <>
                    <Divider textAlign="left" sx={{marginTop:'18px'}}><Chip label="Dati schede" size="small" /></Divider>
                    <TextField
                        fullWidth
                        required
                        id="totali"
                        label="Schede scrutinate"
                        value={vtotali}
                        onChange={(e) => setVTotali(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="bianche"
                        label="Schede bianche"
                        value={bianche}
                        onChange={(e) => setBianche(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="nulle"
                        label="Schede nulle"
                        value={nulle}
                        onChange={(e) => setNulle(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <Divider textAlign="left" sx={{marginTop:'8px'}}><Chip label="Sindaci" size="small" /></Divider>
                    <TextField
                        fullWidth
                        required
                        id="sciacovelli"
                        label="Sciacovelli"
                        value={sciacovelli}
                        onChange={(e) => setSciacovelli(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="laforgia"
                        label="Laforgia"
                        value={laforgia}
                        onChange={(e) => setLaforgia(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="leccese"
                        label="Leccese"
                        value={leccese}
                        onChange={(e) => setLeccese(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="romito"
                        label="Romito"
                        value={romito}
                        onChange={(e) => setRomito(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <TextField
                        fullWidth
                        required
                        id="mangano"
                        label="Mangano"
                        value={mangano}
                        onChange={(e) => setMangano(e.target.value)}
                        margin="normal"
                        type="number" min="0"
                        inputmode="numeric" pattern="[0-9]*"
                    />
                    <LoadingButton type="submit" variant="contained" color="primary" loading={invio} loadingIndicator="Invio in corso" sx={{ width: '100%', mt: 1 }}>Invia</LoadingButton>
                </>
            )}
            </form>
            <Dialog
                open={openDialogErrore}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{DialogErroreMsg}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} autoFocus>CHIUDI</Button>
                </DialogActions>
                </Dialog>
        </Container>
    );
}

export default InviaSindaco;