import React, { useState, useEffect } from 'react';
import {Input, Checkbox, Image} from "@nextui-org/react";
import { useNavigate } from 'react-router-dom';
import { InputAdornment, TextField, Button, Container, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Divider, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Navigate } from 'react-router-dom';
import { useAccount } from './../contexts/AccountContext';

const LoginPage = () => {
    const { ChangeLogged } = useAccount();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = React.useState(false);
    const [openDialogErrore, setOpenDialogErrore] = React.useState(false);
    const [DialogErroreMsg, setDialogErroreMsg] = useState('');
    const navigate = useNavigate();
  
    const handleCloseDialog = () => {
      setOpenDialogErrore(false);
    };
  
    const handleLogin = async (e) => {
      e.preventDefault();
      if(username === '' || password === '') {
        setDialogErroreMsg('Inserisci email e password');
        setOpenDialogErrore(true);
        return;
      }
      setLoading(true);
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
        var urlencoded = new URLSearchParams();
        urlencoded.append("username", username);
        urlencoded.append("password", password);
  
        var requestOptions = {
          credentials: 'include',
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
  
        fetch('https://service.votibari.it/api/login/', requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.data.loggato) {
              ChangeLogged();
              navigate('/');
            }
          })
          .catch(error => {
            setDialogErroreMsg('Credenziali non corrette.');
            setOpenDialogErrore(true);
            return;
          });
          setLoading(false);
          ChangeLogged();
          return <Navigate to="/" />;
      } catch (error) {
        /*AntDialog.show({
          title: "OPS!",
          content: (
            <>
              <div>{t("error_occurred")}</div>
            </>
          ),
          closeOnAction: true,
          actions: [[
                {
                    key: 'cancel',
                    text: 'Chiudi',
                    bold: true,
                }
            ]],
        });*/
        //setLoading(false);
      }
    };
  
    return (
      <Container maxWidth="sm" style={{display:'flex',alignItems:'center',height:'100vh'}}>
        <div>
        <Image style={{width:'100%',marginBottom:'35px'}} src="https://vito-leccese.it/contents/static/assets-vari/6c6f676f2d6c6563636573652d73696e6461636f2d6269616e636f.png" disableWrapper={true} />
        <form onSubmit={handleLogin}>
          <TextField
            fullWidth
            label={"Numero di telefono"}
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            margin="normal"
            InputProps={{
              startAdornment: <InputAdornment position="start">+39</InputAdornment>,
            }}
          />
          <TextField
            fullWidth
            label={"Password"}
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
          />
          <LoadingButton type="submit" variant="contained" color="primary" loading={loading} loadingIndicator="Accesso in corso" sx={{ width: '100%', mt: 1 }}>Accedi</LoadingButton>
        </form>
        </div>
        <Dialog
          open={openDialogErrore}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">OPS! Si è verificato un problema!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{DialogErroreMsg}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} autoFocus>CHIUDI</Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  };
  
  export default LoginPage;
  