import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { useNavigate } from 'react-router-dom';

export default function Top({tawkMessenger}) {
  const navigate = useNavigate();
  return (
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => navigate('/')}
          >
            <HomeRoundedIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
          >
            DatiVoti
          </Typography>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open help"
            onClick={() => tawkMessenger.current.toggle()}
          >
            <HelpOutlineRoundedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
  );
}