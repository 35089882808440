import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAccount } from './../../contexts/AccountContext';
import { Box, CircularProgress } from '@mui/material';

function RequireAuth({children}) {
    const { account } = useAccount();
    let location = useLocation();
    if(account) {
        if (account.loggato) {
            return children;
        } else {
            return <Navigate to="/login" state={{ from: location }} replace />;
        }
    } else {
        return <Box sx={{ display: 'flex', width:'100%',height:'100vh',alignItems:'center', justifyContent:'center' }}><CircularProgress /></Box>;
    }
}

export default RequireAuth;